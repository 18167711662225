import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useLangContext } from '../../context/lang.context'
import Headline from '../../components/Headline'
import Section from '../../components/Section'
import { s } from '../../style'
import alt from '../../content/alt'

const content = {
  title: {
    pl: 'Osiągnięcia',
    en: 'Awards',
  },
  desc: {
    pl: 'OMIDA Group może pochwalić się licznym osiągnięciami tj. prestiżową nagrodę miesięcznika Forbes 2019, czy nagrodami Gazele Biznesu, przyznawane najdynamiczniej rozwijającym się małym i średnim przedsiębiorstwom, którą otrzymała w latach 2014, 2015, 2016 oraz 2018.',
    en: 'OMIDA Group can boast numerous achievements, i.e. the prestigious award of the Forbes 2019 monthly, or the Gazele Biznesu awards, awarded to the most dynamically developing small and medium-sized enterprises, which it received in 2014, 2015, 2016 and 2018.',
  },
  data: [
    { image: 'tsl23' },
    { image: 'tsl24' },
    { image: 'tsl25' },
    { image: 'tsl26' },
    { image: 'tsl27' },
    { image: 'tsl28' },
    { image: 'apg' },
    { image: 'forbes2019' },
    { image: 'gazelebiznesu' },
    { image: 'gryf22' },
    { image: 'forbes23' },
    { image: 'gptw23' },
    { image: 'gptw24' },
  ],
}

const Awards = () => {
  const { lang } = useLangContext()

  const images = useStaticQuery(graphql`
    {
      forbes2019: file(
        absolutePath: { regex: "/images/poznaj-nas/awards/forbes2019.jpg/" }
      ) {
        publicURL
        extension
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG, formats: PNG)
        }
      }
      gazelebiznesu: file(
        absolutePath: { regex: "/images/poznaj-nas/awards/gazelebiznesu.png/" }
      ) {
        publicURL
        extension
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG, formats: PNG)
        }
      }
      gryf22: file(
        absolutePath: { regex: "/images/poznaj-nas/awards/gryf_2022.png/" }
      ) {
        publicURL
        extension
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG, formats: PNG)
        }
      }
      forbes23: file(
        absolutePath: { regex: "/images/poznaj-nas/awards/forbes_2023.png/" }
      ) {
        publicURL
        extension
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG, formats: PNG)
        }
      }
      gptw23: file(
        absolutePath: { regex: "/images/poznaj-nas/awards/gptw23.png/" }
      ) {
        publicURL
        extension
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG, formats: PNG)
        }
      }
      gptw24: file(
        absolutePath: { regex: "/images/poznaj-nas/awards/gptw24.png/" }
      ) {
        publicURL
        extension
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG, formats: PNG)
        }
      }
      tsl23: file(
        absolutePath: { regex: "/images/poznaj-nas/awards/tsl23.svg/" }
      ) {
        publicURL
        extension
      }
      tsl24: file(
        absolutePath: { regex: "/images/poznaj-nas/awards/tsl24.svg/" }
      ) {
        publicURL
        extension
      }
      tsl25: file(
        absolutePath: { regex: "/images/poznaj-nas/awards/tsl25.svg/" }
      ) {
        publicURL
        extension
      }
      tsl26: file(
        absolutePath: { regex: "/images/poznaj-nas/awards/tsl26.svg/" }
      ) {
        publicURL
        extension
      }
      tsl27: file(
        absolutePath: { regex: "/images/poznaj-nas/awards/tsl27.png/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG, formats: PNG)
        }
      }
      tsl28: file(
        absolutePath: { regex: "/images/poznaj-nas/awards/tsl28.png/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG, formats: PNG)
        }
      }
      apg: file(
        absolutePath: { regex: "/images/poznaj-nas/awards/apg.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG, formats: JPG)
        }
      }
    }
  `)

  console.log(images)

  return (
    <>
      <Section top bottom id='osiagniecia'>
        <Headline
          h={2}
          title={content.title[lang]}
          desc={content.desc[lang]}
          large
          gradient
        />
        <div css={sContainer}>
          {content.data.map((award, id) =>
            images[award.image].extension !== 'svg' ? (
              <GatsbyImage
                css={sImage}
                key={id}
                image={getImage(images[award.image].childImageSharp)}
                alt={award.image + alt}
                imgStyle={{ objectFit: 'contain' }}
              />
            ) : (
              <img
                css={[
                  sImage,
                  {
                    [s.xs]: {
                      maxWidth: 'calc(50vw - 2rem)',
                      maxHeight: 'calc(50vw - 2rem)',
                    },
                    [s.md]: {
                      maxWidth: 160,
                      maxHeight: 160,
                    },
                    [s.lg]: {
                      maxWidth: 230,
                      maxHeight: 230,
                    },
                  },
                ]}
                key={id}
                src={images[award.image].publicURL}
                alt={award.image + alt}
              />
            )
          )}
        </div>
      </Section>
    </>
  )
}

const sContainer = {
  display: 'grid',
  marginTop: '2rem',
  gridRowGap: '1rem',
  gridColumnGap: '1rem',
  [s.sm_down]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [s.md]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridRowGap: '0rem',
    gridColumnGap: '4rem',
  },
}

const sImage = {
  objectFit: 'contain',

  [s.lg]: {
    margin: '2rem',
  },
}

export default Awards
